import {
  ChainSUI,
} from "utils/helper";
import {
  updatePaylinkStatusWithApi,
  updatePosStatusWithApi,
} from "containers/CheckoutContainer/constant";
import SuiQRComponent from '../SuiQRComponent';
import SolanaQRComponent from '../SolanaQRComponent';

const PaymentQRComponent = ({
  amount,
  cusEmail,
  setIsPaymentSuccess,
  setManageDigest,
  generateJsonContent,
  qrContentTitle,
  merchantContent,
  businessContent,
  setShowNextCompleted,
}: any) => {

  const onSuccess = (digest: any) => {
    setManageDigest(digest);
    setShowNextCompleted(true);
    // setIsPaymentSuccess(true);
  };

  const onPaymentSuccess = (
    content: any,
    digest: any,
    sender: any,
    getKey: any
  ) => {
    // setQrStatus(5);
    if (merchantContent.order_type === "pos") {
      updatePosStatusWithApi(
        content,
        digest,
        sender,
        merchantContent,
        onSuccess,
        cusEmail
      );
    } else {
      updatePaylinkStatusWithApi(
        content,
        digest,
        sender,
        merchantContent,
        onSuccess,
        cusEmail
      );
    }
  };

  return (
    <>
      {
        generateJsonContent.blockchain === ChainSUI ? (
          <SuiQRComponent
            amount={amount}
            setManageDigest={setManageDigest}
            generateJsonContent={generateJsonContent}
            businessContent={businessContent}
            onPaymentSuccess={onPaymentSuccess}
          />
        ) : (
          <SolanaQRComponent
            amount={amount}
            generateJsonContent={generateJsonContent}
            businessContent={businessContent}
            onPaymentSuccess={onPaymentSuccess}
          />
        )
      }
    </>
  );
};

export default PaymentQRComponent;
