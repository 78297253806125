import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import BigNumber from "bignumber.js";
import { Col, Row, Tooltip, Image } from "antd";
import { PublicKey, Keypair, Connection } from "@solana/web3.js";
import {
    findReference,
    validateTransfer,
    encodeURL,
    createQR,
} from "@solana/pay";
import {
    ChainSOL,
    ChainSolanaBONK,
    ChainSolanaJUP,
    ChainSolanaPYTH,
    ChainSolanaUSDC,
    ChainSolanaUSDT,
    ChainSolanaWEN,
    identifyCoinNetwork,
    solTokenAddresses,
    toastMessage,
} from "utils/helper";
import { AppleIcon, PlayStoreIcon } from "svgIcon";
import PaymentCouldntFetchComponent from "../PaymentCouldntFetchComponent";
import { useStyles } from "components/PaymentOptions/PayWithQR/PaymentQRComponent/style";

const SolanaQRComponent = ({
    amount,
    generateJsonContent,
    businessContent,
    onPaymentSuccess
}: any) => {
    const classes = useStyles();
    const [qrStatus, setQrStatus] = useState(0);
    const [qrKey, setQrKey] = useState({});
    const [qrString, setQrString] = useState("");
    const [showTiming, setShowTiming] = useState(businessContent.end_time);
    const refIsReloadInProgress = useRef(false);
    const [showTxnInput, setShowTxnInput] = useState(false);
    const [qrCodeURL, setQrCodeURL] = useState("");

    const subSOLCoinNames = [
        ChainSolanaUSDC,
        ChainSolanaUSDT,
        ChainSolanaPYTH,
        ChainSolanaBONK,
        ChainSolanaWEN,
        ChainSolanaJUP,
    ];
    const isItSolSubTokens = subSOLCoinNames.includes(
        generateJsonContent.blockchain
    );

    let checkData = 1;
    let timeCheck = businessContent.end_time;

    const onfetchSolanaTransaction = async (
        content: any,
        randomKey: any,
        newReference: any
    ) => {
        try {
            const SolRPC = "https://devnet.helius-rpc.com/?api-key=89fe15c6-d403-4de5-bb77-d66a6a66e615"
            // "https://mainnet.helius-rpc.com/?api-key=af28767a-214e-498f-9b5a-67c30bb6ed81";
            const connection = new Connection(SolRPC, "confirmed");

            if (!newReference) {
                toastMessage("No reference found.");
                return;
            }

            let tokenAddress;
            if (isItSolSubTokens) {
                const usdcTokenAddress = solTokenAddresses(
                    generateJsonContent.blockchain
                );
                if (usdcTokenAddress) {
                    tokenAddress = new PublicKey(usdcTokenAddress);
                }
            }
            const splToken = tokenAddress !== null ? tokenAddress : undefined;

            const signatureInfo = await findReference(connection, newReference);
            const isValid = await validateTransfer(
                connection,
                signatureInfo.signature,
                {
                    recipient: new PublicKey(content.merchant_address),
                    amount: new BigNumber(content.amount),
                    // splToken: new PublicKey(splToken as any),
                    splToken: splToken,
                }
            );

            if (
                isValid &&
                isValid.transaction &&
                isValid.transaction.message &&
                isValid.transaction.message.accountKeys[0]
            ) {
                const getSender = isValid.transaction.message.accountKeys[0].toBase58();
                toastMessage("Payment validated successfully!");
                onPaymentSuccess(
                    content,
                    signatureInfo.signature,
                    getSender,
                    randomKey
                );
                checkData += 1;
            }
        } catch (error) {
            if (checkData == 1 && timeCheck !== 0) {
                timeCheck -= 1;
                setShowTiming(timeCheck);
                setTimeout(() => {
                    onfetchSolanaTransaction(content, randomKey, newReference);
                }, 1500);
            } else {
                setQrStatus(4);
            }
        }
    };

    const generateQRCodeAndSetState = async (
        generateJsonContent: any,
        splToken: any,
        newReference: any
    ) => {
        const recipientPublicKey = new PublicKey(
            generateJsonContent.merchant_address
        );
        const newSolanaQrPaymentObject = encodeURL({
            recipient: recipientPublicKey,
            amount: new BigNumber(generateJsonContent.amount),
            splToken:
                splToken !== undefined ? new PublicKey(splToken as any) : splToken,
            reference: newReference,
            label: generateJsonContent.merchant_name,
            message: "Thanks for paying using PocketPay!",
            memo: `${generateJsonContent.order_id}`,
        });

        const qr = createQR(newSolanaQrPaymentObject);
        const qrBlob = await qr.getRawData("png");

        if (!qrBlob) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            if (typeof event.target?.result === "string") {
                setQrCodeURL(event.target.result);
            }
        };
        reader.readAsDataURL(qrBlob);

        const commerceString = JSON.stringify(generateJsonContent);
        setQrKey(generateJsonContent);
        setQrString(commerceString);
    };

    const onLoadGenerateContent = async (newReference: any) => {
        if (generateJsonContent.blockchain === ChainSOL) {
            await generateQRCodeAndSetState(
                generateJsonContent,
                undefined,
                newReference
            );
            return;
        }
        if (isItSolSubTokens) {
            const usdcTokenAddress = solTokenAddresses(
                generateJsonContent.blockchain
            );
            if (usdcTokenAddress) {
                await generateQRCodeAndSetState(
                    generateJsonContent,
                    usdcTokenAddress,
                    newReference
                );
                return;
            }
        }
        const commerceString = JSON.stringify(generateJsonContent);
        setQrKey(generateJsonContent);
        setQrString(commerceString);
    };

    const onLoadGenerateQr = async () => {
        setQrStatus(1);
        setShowTiming(businessContent.end_time);

        const newReference = new Keypair().publicKey;
        onLoadGenerateContent(newReference);
        onfetchSolanaTransaction(
            generateJsonContent,
            generateJsonContent.order_id,
            newReference
        );
    };

    const SolanaCoinReturn = () => {
        return (
            <>
                <div className="clItems">
                    <p className="m-0 text-dark fontWeight600">
                        Scan QR with PocketPay Wallet
                    </p>
                    <div className="clIApp d-flex flex-wrap align-items-center mt-1">
                        <div className="me-2 d-flex">
                            <Tooltip
                                placement="top"
                                className="cursorPointer"
                                title="App Store"
                            >
                                <span className="d-flex mb-1">
                                    <AppleIcon className="cursorPointer" />
                                </span>
                            </Tooltip>
                        </div>
                        <div className="d-flex">
                            <Tooltip
                                placement="top"
                                className="cursorPointer"
                                title="Play Store"
                            >
                                <span className="d-flex mb-1">
                                    <PlayStoreIcon className="cursorPointer" />
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    useEffect(() => {
        onLoadGenerateQr();
    }, []);

    return (
        <Row gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }} align={"middle"}>
            <Col span={14} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }}>
                <div className={classNames(classes.cainList)}>
                    <div className="clItems">
                        <p className="m-0 text-dark fontWeight600">Chain Name</p>
                        <h4 className="m-0 fontWeight800">
                            {generateJsonContent.blockchain}
                        </h4>
                    </div>
                    <div className="clItems">
                        <p className="m-0 text-dark fontWeight600">
                            Amount:
                            {Object.keys(generateJsonContent).length > 0 && (
                                <span
                                    className="text-primary fontWeight700 mx-1"
                                    style={{ position: "relative", top: "1px" }}
                                >{`${amount} ${generateJsonContent?.blockchain}`}</span>
                            )}
                        </p>
                        <h4 className="m-0 fontWeight800">
                            {"Network - "}
                            {identifyCoinNetwork(generateJsonContent.blockchain)}
                        </h4>
                    </div>
                    <SolanaCoinReturn />
                </div>
            </Col>
            <Col span={10} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
                <div className={classNames(classes.payQr)}>
                    {qrString !== "" && (
                        <>
                            <h3 className="mt-0 mb-2 fontWeight700 px-md-5 text-dark">
                                Pay using SolanaPay compatible wallet
                            </h3>
                            {qrStatus === 4 ? (
                                <PaymentCouldntFetchComponent
                                    qrKey={qrKey}
                                    onLoadGenerateQr={onLoadGenerateQr}
                                    setShowTxnInput={setShowTxnInput}
                                />
                            ) : (
                                <Image
                                    src={qrCodeURL}
                                    title="Scan to pay"
                                    alt="Scan to pay"
                                    width={260}
                                    height={260}
                                />
                            )}
                            <p className="m-0">QR Expires in {showTiming} seconds</p>
                        </>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default SolanaQRComponent;
