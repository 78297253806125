import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ToastContainer } from "react-toastify";
import {
  ChainAptos,
  ChainSUI,
  decodeContent,
  extractID,
  getCoinAmount,
  isSOLPBCoins,
  toastMessage,
} from "utils/helper";
import {
  getMerchantAddress,
  getMerchantSetting,
  getSingleOrder,
} from "apiServices/app";
import {
  isClientNetwork,
  isCoinType,
  manageCoinsSequences,
} from "containers/CheckoutContainer/constant";
import CompeletedOrderComponent from "../CompeletedOrderComponent";
import ChainSelectionComponent from "../ChainSelectionComponent";
import PayOptionsComponent from "components/PaymentOptions/PayOptionsComponent";
import { coinFields } from './constant';

const CheckoutComponent = () => {
  const currentDate = new Date();
  const [qrContent, setQrContent] = useState<any>({});
  const [showLoader, setShowLoader] = useState(false);
  const [businessContent, setBusinessContent] = useState<any>({});
  const [merchantContent, setMerchantContent] = useState<any>({});
  const [completedContent, setCompletedContent] = useState<any>({});
  const [contentTitle, setContentTitle] = useState("");
  const [manageDigest, setManageDigest] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showExpiredPage, setShowExpiredPage] = useState(false);
  const [showResPayUsing, setShowResPayUsing] = useState(false);
  const [payWithWallet, setPayWithWallet] = useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isSelectChain, setIsSelectChain] = useState(false);
  const [isSuiChain, setIsSuiChain] = useState(true);
  const [chainName, setChainName] = useState("");
  const [chainList, setChainList] = useState([]);
  const [amount, setAmount] = useState("0");
  const [isCompletedOrder, setIsCompletedOrder] = useState(false);
  const [cusEmail, setCusEmail] = useState("");
  const [manageChainAmount, setManageChainAmount] = useState({
    ...coinFields
  });

  const onGenerateQrContent = (amount: any) => {
    const randomKey = Math.floor(1000000 + Math.random() * 9000000);
    const uniqueNetworks1 = chainList.filter(
      (v: any, i: any, a: any) =>
        a.findIndex((t: any) => t.blockchain_name === v.blockchain_name) === i
    );
    const allValues: any[] = [];
    chainList.forEach((network: any) => {
      if (network.sub_coin && network.sub_coin.length > 0) {
        for (let i = 0; i < network.sub_coin.length; i++) {
          allValues.push(network.sub_coin[i]);
        }
      }
    });
    const combinedArray = [...uniqueNetworks1, ...allValues];
    const walletAddresses = combinedArray
      .filter(
        (item: any) => item.blockchain_name === chainName && item.wallet_address
      )
      .map((item: any) => item.wallet_address);

    if (!walletAddresses.length) return;

    const baseContent = {
      merchant_address: walletAddresses[0],
      merchant_name: merchantContent.business_name,
      order_id: randomKey,
      amount: amount,
      network: isClientNetwork,
      blockchain: chainName,
    };

    let generateContent;

    if (chainName === ChainAptos) {
      generateContent = { ...baseContent, coin_name: "APT" };
    } else if (chainName === ChainSUI) {
      generateContent = { ...baseContent, coin_name: chainName, coin_type: isCoinType };
    } else if (isSOLPBCoins(chainName)) {
      generateContent = { ...baseContent };
    }

    generateContent && setQrContent(generateContent);
  };

  const getMerchantAddresses = (getOrderData: any) => {
    try {
      getMerchantAddress(getOrderData.merchant_id)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.length > 0) {
            const activeNetworks = responseData.filter(
              (network: any) => network.is_active === 1
            );
            // setChainList(activeNetworks);
            manageCoinsSequences(activeNetworks, setChainList);
            setMerchantContent(getOrderData);
            setAmount(getOrderData.amount);
            setShowLoader(false);
          } else {
            toastMessage("You don't have blockchain profile");
            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) { }
  };

  const getPaylinkMerchantAddress = (
    getMerchantData: any,
    getChainData: any
  ) => {
    try {
      getMerchantAddress(getMerchantData.merchant_id)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.length > 0) {
            const filteredArray = responseData.filter((item1: any) => {
              return getChainData.some(
                (item2: any) => item2 === item1.blockchain_name
              );
            });
            // setChainList(filteredArray);
            manageCoinsSequences(filteredArray, setChainList);
            setMerchantContent(getMerchantData);
            setAmount(getMerchantData && getMerchantData.amount);
            setShowLoader(false);
          } else {
            toastMessage("You don't have blockchain profile");
            setShowLoader(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) { }
  };

  const onGetBusinessDetails = (getId: any) => {
    try {
      getMerchantSetting(getId)
        .then((response) => {
          const responseData = response.data || response;
          responseData && setBusinessContent(responseData);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error: any) { }
  };

  const getPaylinkOrderDetails = (id: any, name: any) => {
    setShowLoader(true);
    amount !== null && getCoinAmount(setManageChainAmount);
    try {
      getSingleOrder(id)
        .then((response) => {
          const responseData = response.data || response;
          if (responseData.success !== false) {
            responseData.merchant_id && onGetBusinessDetails(responseData.merchant_id);
            if (responseData.status === "completed") {
              setCompletedContent(responseData);
              setIsCompletedOrder(true);
              setShowLoader(false);
              return;
            } else {
              responseData.order_type === "POS" ? getMerchantAddresses(responseData) :
                getPaylinkMerchantAddress(responseData, responseData.blockchain);
            }
          } else {
            toastMessage("No data found for this order ID.");
            setShowLoader(false);
            setShowExpiredPage(true);
          }
        })
        .catch((error) => {
          toastMessage(error);
          setShowLoader(false);
        });
    } catch (error: any) { }
  };

  const fetchData = async () => {
    try {
      const input = window.location.pathname;
      const modifiedInput = input.replace("/checkout/", "");
      const decryptValue = decodeContent(modifiedInput);
      const result = extractID(decryptValue);
      if (result) {
        const { name, id } = result;
        getPaylinkOrderDetails(id, name);
        setContentTitle(name);
      }
    } catch (error) {
      console.log("Something went wrong");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (Object.keys(merchantContent).length > 0) {
      const linkExpireTime = merchantContent.link_expire_time
        ? new Date(merchantContent.link_expire_time)
        : null;
      const isExpired = linkExpireTime && linkExpireTime < currentDate;
      isExpired && setShowExpiredPage(true);
    }
  }, [merchantContent]);

  useEffect(() => {
    isPaymentSuccess && fetchData();
  }, [isPaymentSuccess]);

  const renderLoader = () => (
    <div className="loaderCenterWrapper">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
    </div>
  );

  const renderNoDataFound = () => (
    <div className="loaderCenterWrapper" style={{ textAlign: "center" }}>
      No data found for this order ID.
    </div>
  );

  const renderCompletedOrder = () => (
    <CompeletedOrderComponent
      completedContent={completedContent}
      businessContent={businessContent}
    />
  );

  const renderPayOptions = () => (
    <PayOptionsComponent
      cusEmail={cusEmail}
      fetchData={fetchData}
      manageDigest={manageDigest}
      setManageDigest={setManageDigest}
      setIsSelectChain={setIsSelectChain}
      payWithWallet={payWithWallet}
      setIsPaymentSuccess={setIsPaymentSuccess}
      qrContent={qrContent}
      contentTitle={contentTitle}
      amount={amount}
      merchantContent={merchantContent}
      manageChainAmount={manageChainAmount}
      setPayWithWallet={setPayWithWallet}
      businessContent={businessContent}
    />
  );

  const renderChainSelection = () => (
    <ChainSelectionComponent
      contentTitle={contentTitle}
      businessContent={businessContent}
      cusEmail={cusEmail}
      setCusEmail={setCusEmail}
      setShowResPayUsing={setShowResPayUsing}
      isSelectChain={isSelectChain}
      showResPayUsing={showResPayUsing}
      setIsSelectChain={setIsSelectChain}
      setIsSuiChain={setIsSuiChain}
      setChainName={setChainName}
      chainName={chainName}
      isSuiChain={isSuiChain}
      setAmount={setAmount}
      amount={amount}
      data={chainList}
      merchantContent={merchantContent}
      manageChainAmount={manageChainAmount}
      onGenerateQrContent={onGenerateQrContent}
      qrContent={qrContent}
    />
  );

  const renderContent = () => {
    if (showLoader) return renderLoader();
    if (showExpiredPage) return renderNoDataFound();
    if (isCompletedOrder && Object.keys(completedContent).length > 0) return renderCompletedOrder();
    if (errorMessage === "" && Object.keys(merchantContent).length > 0 && manageChainAmount?.sui !== 0) {
      if (isSelectChain) {
        if (Object.keys(qrContent).length > 0) {
          return isPaymentSuccess ? renderCompletedOrder() : renderPayOptions();
        }
      } else {
        return renderChainSelection();
      }
    }
    return null;
  };

  return (
    <>
      <ToastContainer />
      {renderContent()}
    </>
  );
};

export default CheckoutComponent;
