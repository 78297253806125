import React, { useEffect } from "react";
import { TransactionBlock } from "@mysten/sui.js";
import { useWalletKit } from "@mysten/wallet-kit";
import { toastMessage } from "utils/helper";
import SuiWalletConnect from "components/ConnectToWallet/sui";

const SuiWalletComponent = ({
  isLoading,
  generateJsonContent,
  setLoading,
  setManageLabel,
  onPaymentSuccess,
}: any) => {
  const getIsConnected = localStorage.getItem("wallet-kit:last-wallet");
  const { signAndExecuteTransactionBlock, currentAccount } = useWalletKit();

  const sendSUITxn = async () => {
    setLoading(true);
    try {
      const tx = new TransactionBlock();
      let cointype0 = "0x2::sui::SUI";
      const txMuldyValue = Math.floor(
        +generateJsonContent?.amount * 1_000_000_000
      );
      const [coins] = tx.splitCoins(tx.gas, [tx.pure(txMuldyValue)]);
      tx.moveCall({
        target: `0x158f2fe3d40088a0935e29de6c1986da5128b2f59cae18cdcd453708018e2040::pocketpay::create_tx`,
        typeArguments: [cointype0],
        arguments: [
          coins,
          tx.pure(generateJsonContent?.merchant_address),
          tx.pure(txMuldyValue),
          tx.pure(generateJsonContent?.order_id),
        ],
      });
      const result = await signAndExecuteTransactionBlock({
        transactionBlock: tx,
        options: {
          showEffects: true,
        },
      });
      if (
        result.effects &&
        result.effects.status &&
        result.effects.status.status
      ) {
        const getStatus = result.effects.status.status;
        if (getStatus === "success") {
          onPaymentSuccess(
            generateJsonContent,
            result.digest,
            result.digest,
            generateJsonContent?.order_id
          );
        } else {
          setLoading(false);
          toastMessage("fail");
        }
      }
    } catch (error) {
      setLoading(false);
      toastMessage(error);
    }
  };

  const identifyTxn = () => {
    if (getIsConnected !== null && getIsConnected !== undefined) {
      sendSUITxn();
    }
  };

  useEffect(() => {
    if (isLoading) {
      setManageLabel("Process to connecting...");
    } else if (currentAccount) {
      setManageLabel("Wallet connected, Pay now");
    } else {
      setManageLabel("Connect your wallet to make a payment");
    }
  }, [currentAccount, isLoading]);

  return <SuiWalletConnect identifyTxn={identifyTxn} />;
};

export default SuiWalletComponent;
