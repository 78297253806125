export const coinFields = {
    sui: 0,
    solana: 0,
    blast: 0,
    bnb: 0,
    polygon: 0,
    base: 0,
    linea: 0,
    taiko: 0,
    usdt: 0,
    pyth: 0,
    bonk: 0,
    wen: 0,
    jup: 0,
}