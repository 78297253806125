import React, { useState } from "react";
import { Tooltip } from "antd";
import { ConnectModal, useWalletKit } from "@mysten/wallet-kit";
import ButtonComponent from "components/Forms/ButtonComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { copyContent } from 'utils/helper';
import "./style.css";

const SuiWalletConnect = ({ identifyTxn }: any) => {
  const { currentAccount, disconnect } = useWalletKit();
  const [showConnectModal, setShowConnectModal] = useState(false);

  const ButtonDisconnect = () => {
    const keys = currentAccount?.address;
    const firstFour = keys?.substring(0, 4);
    const lastFour = keys?.slice(keys?.length - 4);
    const result = firstFour + "..." + lastFour;

    const walletDisconnect = () => {
      disconnect();
      setShowConnectModal(false);
    };

    return (
      <div className="text-center bg-light py-4 px-5 d-inline-block rounded-2">
        <Tooltip
          placement="top"
          className="cursorPointer mt-0 mb-3 d-flex align-items-center justify-content-center"
          title="Copy"
        >
          <h3 className="m-0 pe-2">{result}</h3>
          <span onClick={() => copyContent(result)}>
            <FontAwesomeIcon className="opacity40" icon={faCopy} />
          </span>
        </Tooltip>
        <div className="text-center d-flex flex-wrap align-items-center justify-content-center">
          <Tooltip placement="right" className="cursorPointer" title="Pay now">
            <ButtonComponent
              extraClass="mb-2 w-100"
              style={{ maxWidth: "260px" }}
              btnPrimary
              onClick={() => identifyTxn()}
            >
              Pay Now
            </ButtonComponent>
          </Tooltip>
          <Tooltip
            placement="right"
            className="cursorPointer"
            title="Disconnect your wallet"
          >
            <ButtonComponent
              extraClass="w-100"
              style={{ maxWidth: "260px" }}
              btnSecondaryOutline
              onClick={() => walletDisconnect()}
            >
              Disconnect
            </ButtonComponent>
          </Tooltip>
        </div>
      </div>
    );
  };

  const ButtonConnect = () => {
    if (currentAccount) {
      return <ButtonDisconnect />;
    }

    return (
      <>
        <ConnectModal
          open={showConnectModal}
          onClose={() => setShowConnectModal(false)}
        />
        <ButtonComponent
          className="w-100"
          style={{ maxWidth: "260px" }}
          title={"Connect & Pay"}
          onClick={() => setShowConnectModal(true)}
          children={"Connect & Pay"}
          btnPrimary={true}
        />
      </>
    );
  };
  return <ButtonConnect />;
};

export default SuiWalletConnect;
