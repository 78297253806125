import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import classNames from "classnames";
import { QRCodeCanvas } from "qrcode.react";
import { Col, Row, Tooltip, Image } from "antd";
import {
    identifyCoinNetwork,
} from "utils/helper";
import {
    networkUrl,
    packageId,
} from "containers/CheckoutContainer/constant";
import { AppleIcon, PlayStoreIcon } from "svgIcon";
import PaymentCouldntFetchComponent from "../PaymentCouldntFetchComponent";
import PaymentVerifyBoxComponent from "../PaymentVerifyBoxComponent";
import { useStyles } from "components/PaymentOptions/PayWithQR/PaymentQRComponent/style";

const SuiQRComponent = ({
    amount,
    setManageDigest,
    generateJsonContent,
    businessContent,
    onPaymentSuccess
}: any) => {
    const classes = useStyles();
    const [qrStatus, setQrStatus] = useState(0);
    const [qrKey, setQrKey] = useState({});
    const [qrString, setQrString] = useState("");
    const refIsReloadInProgress = useRef(false);
    const [showTxnInput, setShowTxnInput] = useState(false);
    const [showTiming, setShowTiming] = useState(businessContent.end_time);

    let checkData = 1;
    let timeCheck = businessContent.end_time;

    const onfetchLastSuiTransaction = (content: any, randomKey: any) => {
        if (refIsReloadInProgress.current) {
            return;
        }
        refIsReloadInProgress.current = true;

        const data = {
            method: "suix_queryTransactionBlocks",
            jsonrpc: "2.0",
            params: [
                {
                    filter: {
                        InputObject: packageId,
                    },
                    options: {
                        showEffects: true,
                        showInput: true,
                    },
                },
                null,
                1,
                true,
            ],
            id: "1",
        };

        try {
            axios
                .post(networkUrl, data)
                .then(({ data }) => {
                    const { result } = data;
                    if (result) {
                        const { data } = result;
                        if (
                            data &&
                            data[0] &&
                            data[0].transaction &&
                            data[0].transaction.data.transaction.inputs.length === 5 &&
                            data[0].transaction.data.transaction.inputs[1].value ===
                            randomKey.toString() &&
                            data[0].transaction.data.transaction.inputs[4].value ==
                            content.amount * 1000000000 &&
                            data[0].transaction.data.transaction.inputs[3].value ==
                            content.merchant_address
                        ) {
                            if (
                                data &&
                                data[0] &&
                                data[0].effects &&
                                data[0].effects.status &&
                                data[0].effects.status.status == "success"
                            ) {
                                onPaymentSuccess(
                                    content,
                                    data[0].digest,
                                    data[0].transaction.data.sender,
                                    randomKey
                                );
                                checkData += 1;
                            } else {
                                setQrStatus(3);
                            }
                        } else {
                            if (checkData == 1 && timeCheck !== 0) {
                                timeCheck -= 1;
                                setShowTiming(timeCheck);
                                setTimeout(() => {
                                    onfetchLastSuiTransaction(content, randomKey);
                                }, 1500);
                            } else {
                                setQrStatus(4);
                            }
                        }
                    }
                })
                .catch((error: any) => {
                    console.log("OOPS! The SUI network may be down");
                });
        } catch {
            console.log("OOPS! The SUI network may be down");
        }
        refIsReloadInProgress.current = false;
    };

    const onLoadGenerateQr = async () => {
        setQrStatus(1);
        setShowTiming(businessContent.end_time);

        const commerceString = JSON.stringify(generateJsonContent);
        setQrKey(generateJsonContent);
        setQrString(commerceString);

        onfetchLastSuiTransaction(
            generateJsonContent,
            generateJsonContent.order_id
        );
    };

    const SuiCoinReturn = () => {
        return (
            <>
                <div className="clItems">
                    <p className="m-0 text-dark fontWeight600">
                        Scan QR with PocketPay Wallet
                    </p>
                    <div className="clIApp d-flex flex-wrap align-items-center mt-1">
                        <div className="me-2 d-flex">
                            <Tooltip
                                placement="top"
                                className="cursorPointer"
                                title="App Store"
                            >
                                <span className="d-flex mb-1">
                                    <AppleIcon className="cursorPointer" />
                                </span>
                            </Tooltip>
                        </div>
                        <div className="d-flex">
                            <Tooltip
                                placement="top"
                                className="cursorPointer"
                                title="Play Store"
                            >
                                <span className="d-flex mb-1">
                                    <PlayStoreIcon className="cursorPointer" />
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    useEffect(() => {
        onLoadGenerateQr();
    }, []);

    return (
        <Row gutter={{ xs: 10, sm: 10, md: 14, lg: 20 }} align={"middle"}>
            <Col span={14} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }}>
                <div className={classNames(classes.cainList)}>
                    <div className="clItems">
                        <p className="m-0 text-dark fontWeight600">Chain Name</p>
                        <h4 className="m-0 fontWeight800">
                            {generateJsonContent.blockchain}
                        </h4>
                    </div>
                    <div className="clItems">
                        <p className="m-0 text-dark fontWeight600">
                            Amount:
                            {Object.keys(generateJsonContent).length > 0 && (
                                <span
                                    className="text-primary fontWeight700 mx-1"
                                    style={{ position: "relative", top: "1px" }}
                                >{`${amount} ${generateJsonContent?.blockchain}`}</span>
                            )}
                        </p>
                        <h4 className="m-0 fontWeight800">
                            {"Network - "}
                            {identifyCoinNetwork(generateJsonContent.blockchain)}
                        </h4>
                    </div>
                    <SuiCoinReturn />
                </div>
            </Col>
            <Col span={10} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
                <div className={classNames(classes.payQr)}>
                    {qrString !== "" && (
                        <>
                            <h3 className="mt-0 mb-2 fontWeight700 px-md-5 text-dark">
                                Pay using SolanaPay compatible wallet
                            </h3>
                            {qrStatus === 4 ? (
                                <PaymentCouldntFetchComponent
                                    qrKey={qrKey}
                                    onLoadGenerateQr={onLoadGenerateQr}
                                    setShowTxnInput={setShowTxnInput}
                                />
                            ) : (
                                <QRCodeCanvas
                                    title="Scan to pay"
                                    value={qrString}
                                    width={260}
                                    height={260}
                                    size={260}
                                />
                            )}
                            <p className="m-0">QR Expires in {showTiming} seconds</p>
                        </>
                    )}
                    {qrStatus !== 1 && showTxnInput && (
                        <PaymentVerifyBoxComponent
                            onPaymentSuccess={onPaymentSuccess}
                            qrKey={qrKey}
                            setQrStatus={setQrStatus}
                            setManageDigest={setManageDigest}
                        />
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default SuiQRComponent;
